import {
  SIGN_OUT,
  GET_EVOM_ACCOUNT_BALANCE,
  GET_EVOM_ACCOUNT_LAST_TOP_UP_DATA,
  GET_EVOM_ACCOUNT_DETAILS,
  SET_EVOM_ACCOUNT,
  EVOM_ACCOUNT_DETAILS_UPDATE_WALLET,
  EVOM_ACCOUNT_DETAILS_UPDATE_WALLET_STATUS,
  EVOM_ACCOUNT_DETAILS_SHOW_UPDATE_WALLET_FORM,
  EVOM_ACCOUNT_DETAILS_HIDE_UPDATE_WALLET_FORM,
  EVOM_ACCOUNT_DETAILS_SHOW_UPDATE_WALLET_STATUS_FORM,
  EVOM_ACCOUNT_DETAILS_HIDE_UPDATE_WALLET_STATUS_FORM,
  EVOM_ACCOUNT_DETAILS_SHOW_UPDATE_COMPANY_WALLET_TYPE_FORM,
  EVOM_ACCOUNT_DETAILS_HIDE_UPDATE_COMPANY_WALLET_TYPE_FORM,
  EVOM_ACCOUNT_DETAILS_SHOW_RETRIEVE_WALLET_BALANCE_DIALOG,
  EVOM_ACCOUNT_DETAILS_HIDE_RETRIEVE_WALLET_BALANCE_DIALOG,
  UPDATE_EVOM_ACCOUNT,
  UPDATE_EVOM_ACCOUNT_REQUEST,
  UPDATE_EVOM_ACCOUNT_REQUEST_FAILURE,
  START_EVOM_ACCOUNT_UPDATE,
  CANCEL_EVOM_ACCOUNT_UPDATE,
  SHOW_ADD_EVOM_ACCOUNT_AUTH_PROVIDER_FORM,
  HIDE_ADD_EVOM_ACCOUNT_AUTH_PROVIDER_FORM,
  ADD_EVOM_ACCOUNT_AUTH_PROVIDER,
  ADD_EVOM_ACCOUNT_AUTH_PROVIDER_REQUEST,
  ADD_EVOM_ACCOUNT_AUTH_PROVIDER_REQUEST_FAILURE,
  SYNC_EVOM_ACCOUNT_AUTH_PROVIDERS,
  SYNC_EVOM_ACCOUNT_AUTH_PROVIDERS_REQUEST,
  SYNC_EVOM_ACCOUNT_AUTH_PROVIDERS_REQUEST_FAILURE,
  SEND_EVOM_RESET_PASSWORD_EMAIL,
  SEND_EVOM_RESET_PASSWORD_EMAIL_REQUEST,
  SEND_EVOM_RESET_PASSWORD_EMAIL_FAILURE,
  EVOM_ACCOUNT_DETAILS_SHOW_UPDATE_WALLET_RESTRICTIONS,
  EVOM_ACCOUNT_DETAILS_HIDE_UPDATE_WALLET_RESTRICTIONS,
  SHOW_ADD_EVOM_ACCOUNT_REFERRAL_CODE_FORM,
  HIDE_ADD_EVOM_ACCOUNT_REFERRAL_CODE_FORM,
  ADD_EVOM_ACCOUNT_REFERRAL_CODE,
  ADD_EVOM_ACCOUNT_REFERRAL_CODE_REQUEST,
  ADD_EVOM_ACCOUNT_REFERRAL_CODE_REQUEST_FAILURE,
} from "../constants/ActionTypes";

const INIT_STATE = {
  evomAccountUuid: null,
  evomAccountDetails: null,
  walletUpdating: {
    isUpdatingAnWallet: false,
    isUpdatingWalletRestrictions: false,
    currentUpdatingWallet: null
  },
  walletStatusUpdating: {
    isUpdatingAnEvomAccountWalletStatus: false,
    currentUpdatingEvomAccountWalletStatus: null,
  },
  companyWalletTypeUpdating: {
    isUpdatingAnEvomAccountCompanyWalletType: false,
    currentUpdatingEvomAccountCompanyWalletType: null,
  },
  evomAccountUpdating: {
    isUpdating: false,
    loading: false,
  },
  retrievingWalletBalance: {
    isRetrieving: false,
    currentRetrievingWallet: null,
  },
  evomAccountBalance: null,
  evomAccountLastTopUpData: {
    date: null,
    amount: null,
    noTopUpMade: false,
  },
  evomAccountAuthProviderAddition : {
    isAddingAnAuthProvider: false,
    loading: false,
  },
  authProviderAdditionDetails: null,
  evomAccountReferralCodeAddition : {
    isAddingReferralCode: false,
    loading: false,
  },
  referralCodeAdditionDetails: null,
};

export default (state = INIT_STATE, action) => {
  const findByWalletUuid = (uuid, arr) => arr.find(
    wallet => wallet.walletUuid === uuid
  );

  switch (action.type) {
    case SIGN_OUT: {
      return INIT_STATE;
    }

    case SET_EVOM_ACCOUNT: {
      return {
        ...state,
        evomAccountUuid: action.payload,
      };
    }

    case GET_EVOM_ACCOUNT_DETAILS: {
      return {
        ...state,
        evomAccountDetails: action.payload,
      };
    }

    case EVOM_ACCOUNT_DETAILS_UPDATE_WALLET: {
      const newWallets = state.evomAccountDetails.wallets.map(
        wallet => wallet.walletUuid === action.payload.walletUuid ? action.payload : wallet
      );

      return {
        ...state,
        evomAccountDetails: {
          ...state.evomAccountDetails,
          wallets: newWallets,
        },
      };
    }

    case EVOM_ACCOUNT_DETAILS_UPDATE_WALLET_STATUS: {
      const newWallets = state.evomAccountDetails.wallets.map(
        wallet => wallet.walletUuid === action.payload.walletUuid ? action.payload : wallet
      );

      return {
        ...state,
        evomAccountDetails: {
          ...state.evomAccountDetails,
          wallets: newWallets,
        },
      };
    }

    case EVOM_ACCOUNT_DETAILS_SHOW_UPDATE_WALLET_FORM: {
      const currentUpdatingWallet = state.evomAccountDetails.wallets.find(
        wallet => wallet.walletUuid === action.payload
      );

      return {
        ...state,
        walletUpdating: {
          isUpdatingAnWallet: true,
          currentUpdatingWallet: currentUpdatingWallet,
        },
      };
    }

    case EVOM_ACCOUNT_DETAILS_HIDE_UPDATE_WALLET_FORM: {
      return {
        ...state,
        walletUpdating: {
          isUpdatingAnWallet: false,
          currentUpdatingWallet: null,
        },
      };
    }

    case EVOM_ACCOUNT_DETAILS_SHOW_UPDATE_WALLET_RESTRICTIONS: {
      return {
        ...state,
        walletUpdating: {
          ...state.walletUpdating,
          isUpdatingWalletRestrictions: true,
          currentUpdatingWallet: findByWalletUuid(action.payload, state.evomAccountDetails?.wallets ?? []),
        },
      }
    }

    case EVOM_ACCOUNT_DETAILS_HIDE_UPDATE_WALLET_RESTRICTIONS: {
      return {
        ...state,
        walletUpdating: {
          ...state.walletUpdating,
          isUpdatingWalletRestrictions: false,
          currentUpdatingWallet: null,
        },
      }
    }

    case EVOM_ACCOUNT_DETAILS_SHOW_UPDATE_WALLET_STATUS_FORM: {
      const currentUpdatingEvomAccountWalletStatus = state.evomAccountDetails.wallets.find(
        wallet => wallet.walletUuid === action.payload
      );

      return {
        ...state,
        walletStatusUpdating: {
          ...state.updatingEvomAccountWalletStatus,
          isUpdatingAnEvomAccountWalletStatus: true,
          currentUpdatingEvomAccountWalletStatus: currentUpdatingEvomAccountWalletStatus,
        },
      };
    }

    case EVOM_ACCOUNT_DETAILS_HIDE_UPDATE_WALLET_STATUS_FORM: {
      return {
        ...state,
        walletStatusUpdating: {
          ...state.walletStatusUpdating,
          isUpdatingAnEvomAccountWalletStatus: false,
          currentUpdatingEvomAccountWalletStatus: null,
        },
      };
    }

    case EVOM_ACCOUNT_DETAILS_SHOW_UPDATE_COMPANY_WALLET_TYPE_FORM: {
      const currentUpdatingEvomAccountCompanyWalletType = state.evomAccountDetails.wallets.find(
        wallet => wallet.walletUuid === action.payload
      );

      return {
        ...state,
        companyWalletTypeUpdating: {
          ...state.companyWalletTypeUpdating,
          isUpdatingAnEvomAccountCompanyWalletType: true,
          currentUpdatingEvomAccountCompanyWalletType: currentUpdatingEvomAccountCompanyWalletType,
        },
      };
    }

    case EVOM_ACCOUNT_DETAILS_HIDE_UPDATE_COMPANY_WALLET_TYPE_FORM: {
      return {
        ...state,
        companyWalletTypeUpdating: {
          ...state.companyWalletTypeUpdating,
          isUpdatingAnEvomAccountCompanyWalletType: false,
          currentUpdatingEvomAccountCompanyWalletType: null,
        },
      };
    }

    case EVOM_ACCOUNT_DETAILS_SHOW_RETRIEVE_WALLET_BALANCE_DIALOG: {
      const currentRetrievingWalletBalance = state.evomAccountDetails.wallets.find(
        wallet => wallet.walletUuid === action.payload
      );

      return {
        ...state,
        retrievingWalletBalance: {
          ...state.retrievingWalletBalance,
          isRetrieving: true,
          currentRetrievingWallet: currentRetrievingWalletBalance,
        },
      };
    }

    case EVOM_ACCOUNT_DETAILS_HIDE_RETRIEVE_WALLET_BALANCE_DIALOG: {
      return {
        ...state,
        retrievingWalletBalance: {
          ...state.retrievingWalletBalance,
          isRetrieving: false,
          currentRetrievingWallet: null,
        },
      };
    }

    case GET_EVOM_ACCOUNT_BALANCE: {
      return {
        ...state,
        evomAccountBalance: action.payload,
      };
    }

    case GET_EVOM_ACCOUNT_LAST_TOP_UP_DATA: {
      return {
        ...state,
        evomAccountLastTopUpData: action.payload,
      };
    }

    case UPDATE_EVOM_ACCOUNT: {
      return {
        ...state,
        evomAccountDetails: {
          ...state.evomAccountDetails,
          evomAccount: {
            ...state.evomAccountDetails.evomAccount,
            ...action.payload.evomAccount,
          },
        },
        evomAccountUpdating: {
          ...state.evomAccountUpdating,
          isUpdating: false,
          loading: false,
        },
      };
    }

    case UPDATE_EVOM_ACCOUNT_REQUEST: {
      return {
        ...state,
        evomAccountUpdating: {
          ...state.evomAccountUpdating,
          loading: true,
        },
      };
    }

    case UPDATE_EVOM_ACCOUNT_REQUEST_FAILURE: {
      return {
        ...state,
        evomAccountUpdating: {
          ...state.evomAccountUpdating,
          loading: false,
        },
      };
    }

    case START_EVOM_ACCOUNT_UPDATE: {
      return {
        ...state,
        evomAccountUpdating: {
          ...state.evomAccountUpdating,
          isUpdating: true,
        },
      };
    }

    case CANCEL_EVOM_ACCOUNT_UPDATE: {
      return {
        ...state,
        evomAccountUpdating: {
          ...state.evomAccountUpdating,
          isUpdating: false,
        },
      };
    }

    case SHOW_ADD_EVOM_ACCOUNT_AUTH_PROVIDER_FORM: {
      return {
        ...state,
        evomAccountAuthProviderAddition: {
          ...state.evomAccountAuthProviderAddition,
          isAddingAnAuthProvider: true,
        }
      };
    }

    case HIDE_ADD_EVOM_ACCOUNT_AUTH_PROVIDER_FORM: {
      return {
        ...state,
        evomAccountAuthProviderAddition: {
          ...state.evomAccountAuthProviderAddition,
          isAddingAnAuthProvider: false,
        }
      };
    }

    case ADD_EVOM_ACCOUNT_AUTH_PROVIDER: {
      return {
        ...state,
        evomAccountDetails: {
          ...state.evomAccountDetails,
          evomAccount: {
            ...state.evomAccountDetails.evomAccount,
            ...action.payload,
          },
        },
        evomAccountAuthProviderAddition: {
          ...state.evomAccountAuthProviderAddition,
          loading: false,
          isAddingAnAuthProvider: false,
          response: action.payload,
        },
        authProviderAdditionDetails: {
          ...state.authProviderAdditionDetails,
          details: action.payload,
        },
      };
    }

    case ADD_EVOM_ACCOUNT_AUTH_PROVIDER_REQUEST: {
      return {
        ...state,
        evomAccountAuthProviderAddition: {
          ...state.evomAccountAuthProviderAddition,
          loading: true,
        }
      };
    }

    case ADD_EVOM_ACCOUNT_AUTH_PROVIDER_REQUEST_FAILURE: {
      return {
        ...state,
        evomAccountAuthProviderAddition: {
          ...state.evomAccountAuthProviderAddition,
          loading: false,
        }
      };
    }

    case SYNC_EVOM_ACCOUNT_AUTH_PROVIDERS: {
      return {
        ...state,
        evomAccountDetails: {
          ...state.evomAccountDetails,
          evomAccount: {
            ...state.evomAccountDetails.evomAccount,
            ...action.payload.evomAccount,
          },
        },
        evomAccountUpdating: {
          ...state.evomAccountUpdating,
          isUpdating: false,
          loading: false,
        },
      };
    }

    case SYNC_EVOM_ACCOUNT_AUTH_PROVIDERS_REQUEST: {
      return {
        ...state,
        evomAccountUpdating: {
          ...state.evomAccountUpdating,
          loading: true,
        },
      };
    }

    case SYNC_EVOM_ACCOUNT_AUTH_PROVIDERS_REQUEST_FAILURE: {
      return {
        ...state,
        evomAccountUpdating: {
          ...state.evomAccountUpdating,
          loading: false,
        },
      };
    }

    case SEND_EVOM_RESET_PASSWORD_EMAIL: {
      return {
        ...state,
        evomAccountUpdating: {
          ...state.evomAccountUpdating,
          isUpdating: false,
          loading: false,
        },
      };
    }

    case SEND_EVOM_RESET_PASSWORD_EMAIL_REQUEST: {
      return {
        ...state,
        evomAccountUpdating: {
          ...state.evomAccountUpdating,
          loading: true,
        },
      };
    }

    case SEND_EVOM_RESET_PASSWORD_EMAIL_FAILURE: {
      return {
        ...state,
        evomAccountUpdating: {
          ...state.evomAccountUpdating,
          loading: false,
        },
      };
    }

    case SHOW_ADD_EVOM_ACCOUNT_REFERRAL_CODE_FORM: {
      return {
        ...state,
        evomAccountReferralCodeAddition: {
          ...state.evomAccountReferralCodeAddition,
          isAddingReferralCode: true,
        }
      };
    }

    case HIDE_ADD_EVOM_ACCOUNT_REFERRAL_CODE_FORM: {
      return {
        ...state,
        evomAccountReferralCodeAddition: {
          ...state.evomAccountReferralCodeAddition,
          isAddingReferralCode: false,
        }
      };
    }

    case ADD_EVOM_ACCOUNT_REFERRAL_CODE: {
      return {
        ...state,
        evomAccountDetails: {
          ...state.evomAccountDetails,
          evomAccount: {
            ...state.evomAccountDetails.evomAccount,
            ...action.payload,
          },
        },
        evomAccountReferralCodeAddition: {
          ...state.evomAccountReferralCodeAddition,
          loading: false,
          isAddingReferralCode: false,
          response: action.payload,
        },
        referralCodeAdditionDetails: {
          ...state.referralCodeAdditionDetails,
          details: action.payload,
        },
      };
    }

    case ADD_EVOM_ACCOUNT_REFERRAL_CODE_REQUEST: {
      return {
        ...state,
        evomAccountReferralCodeAddition: {
          ...state.evomAccountReferralCodeAddition,
          loading: true,
        }
      };
    }

    case ADD_EVOM_ACCOUNT_REFERRAL_CODE_REQUEST_FAILURE: {
      return {
        ...state,
        evomAccountReferralCodeAddition: {
          ...state.evomAccountReferralCodeAddition,
          loading: false,
        }
      };
    }

    default: {
      return state;
    }
  }
}

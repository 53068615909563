import EvomUtils from 'util/EvomUtils';

export default {

  formatWalletConfigs(walletConfigs) {
    return walletConfigs.map(wallet => ({
      ...wallet,
      activePrerequisitesAmount: this.getActivePrerequisitesAmount(wallet.items),
      lastModifiedDate: this.getWalletLastModifiedDate(wallet.items),
      restrictedWeekDays: wallet.dayTypeRestrictions.map(day => day.weekDay),
    }));
  },

  getActivePrerequisitesAmount(walletConfigItems) {
    let activePrerequisitesAmount = 0;

    walletConfigItems.forEach(configItem => {
      if (configItem.active) {
        activePrerequisitesAmount++;
      }
    });

    return activePrerequisitesAmount;
  },

  getWalletLastModifiedDate(walletConfigItems) {
    const lastModifiedDates = walletConfigItems.map(walletConfigItem => walletConfigItem.lastModifiedDate);
    let lastModifiedDate = lastModifiedDates.find(lastModifiedDate => typeof lastModifiedDate == "string");

    if (lastModifiedDate !== undefined) {
      lastModifiedDates.forEach(lastModifiedDateItem => {
        if (lastModifiedDateItem > lastModifiedDate) {
          lastModifiedDate = lastModifiedDateItem;
        }
      });
      return EvomUtils.formatDateDDMMYYYYHHMMSS(lastModifiedDate);
    }

    return null;
  },

  /**
   * @deprecated prefira usar EvomUtils.formatWalletType
   */
  formatWalletType(type) {
    switch (type) {
      case 'REGULAR':
        return 'Comum';
      case 'WORKER':
        return 'Vale transporte';
      case 'STUDENT':
        return 'Estudante';
      case 'STUDENT_FREE':
        return 'Estudante ensino médio';
      case 'ELDERLY':
        return 'Idoso';
      case 'SPECIAL':
        return 'Especial';
      default:
        return type;
    }
  },

  convertConfigItemtype(type) {
    switch (type) {
      case 'FULL_NAME':
        return 'Nome completo';
      case 'BIRTH_DATE':
        return 'Data de nascimento';
      case 'EMAIL':
        return 'E-mail';
      case 'DRIVERS_LICENSE':
        return 'CNH';
      case 'NATIONAL_ID':
        return 'RG';
      case 'DOCUMENT_NUMBER':
        return 'CPF';
      case 'ADDRESS':
        return 'Endereço';
      case 'ENROLLMENT_ID':
        return 'Número de matrícula';
      case 'MEDICAL_REPORT':
        return 'Laudo médico';
      case 'SELFIE':
        return 'Foto de identificação (selfie)';
        case 'PROOF_OF_PAYMENT':
          return 'Comprovante de Pagamento';
      default:
        return type;
    }
  },

  isSameValue(oldValue, newValue) {
    if (EvomUtils.isArray(oldValue) && EvomUtils.isArray(newValue)) {
      return EvomUtils.arrayDeepEquals(oldValue, newValue);
    } else {
      return oldValue === newValue
    }
  },

  formatDayTypeRestrictionChanges(restrictions) {
    return restrictions.reduce((acc, day, idx) =>
      `${acc}${EvomUtils.formatWeekDays(day, true)}${idx === restrictions.length - 1 ? '' : ', '}`,
      ''
    );
  }
}
